<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    content: string;
    scaleToFit?: boolean;
    scale?: number;
  }>(),
  {
    scale: 100,
  }
);

const container = ref<HTMLDivElement>();
const contentElement = ref<HTMLDivElement>();
const scaleValue = ref<number>(1);
const minScalingValue = 0.1;

const calcScaling = () => {
  if (!props.content) {
    scaleValue.value = 1;
    return;
  }

  if (props.scaleToFit) {
    scaleValue.value = calculateFitScaling();
  } else {
    const normalizedScale = Math.max(
      minScalingValue,
      Math.min(1, (props.scale ?? 100) / 100)
    );
    scaleValue.value = normalizedScale;
  }
};

const calculateFitScaling = () => {
  if (!container.value || !contentElement.value) return 1;

  const maxHeight = container.value.clientHeight;
  const totalHeight = contentElement.value.scrollHeight;
  const maxWidth = container.value.clientWidth;
  const totalWidth = contentElement.value.scrollWidth;

  const fitScale = Math.min(
    maxWidth / totalWidth || 1,
    maxHeight / totalHeight || 1
  );

  return Math.max(minScalingValue, Math.min(1, fitScale));
};

watch([() => props.scale, () => props.scaleToFit], calcScaling);

onMounted(async () => {
  window.addEventListener('resize', calcScaling);
  return { container };
});

onUnmounted(() => {
  window.removeEventListener('resize', calcScaling);
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div ref="container" style="width: 100%">
    <div
      ref="contentElement"
      class="content-element"
      :style="{ '--v-scaling-value': scaleValue }"
      v-html="content"
    />
  </div>
</template>

<style scoped>
.content-element {
  --v-scaling-value: 1;
  transform-origin: 0 0;
  transform: scale(var(--v-scaling-value));
  transition: all 0.1s ease-in-out;
}
</style>
