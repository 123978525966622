<script setup lang="ts">
import { QBanner, QIcon } from 'quasar';
import { computed, toRef } from 'vue';

import WsIconButton from '../button//ws-icon-button.vue';

defineOptions({
  inheritAttrs: false,
});

const openModel = defineModel<boolean>('open');
const open = toRef(() => openModel);

const props = withDefaults(
  defineProps<{
    type?: 'info' | 'warning' | 'danger' | 'info-dark';
    variant?: 'flat' | 'outlined' | 'elevated';
    blockActions?: boolean;
    noIcon?: boolean;
    closable?: boolean;
    aid: string;
    title?: string;
  }>(),
  {
    type: 'info',
    variant: 'outlined',
  }
);

defineSlots<{
  icon(): unknown;
  default(): unknown;
  actions(): unknown;
}>();

const icon = computed(() => {
  if (props.noIcon) return undefined;

  switch (props.type) {
    case 'info':
    case 'info-dark':
      return 'fa-regular fa-circle-info';
    case 'warning':
      return 'fa-regular fa-triangle-exclamation';
    case 'danger':
      return 'fa-regular fa-circle-exclamation';
    default:
      return undefined;
  }
});
</script>

<template>
  <QBanner
    v-if="open.value"
    dense
    :class="[
      'ws-alert',
      {
        'ws-alert--type-info': props.type === 'info',
        'ws-alert--type-warning': props.type === 'warning',
        'ws-alert--type-danger': props.type === 'danger',
        'ws-alert--type-info-dark': props.type === 'info-dark',

        'ws-alert--variant-flat': props.variant === 'flat',
        'ws-alert--variant-outlined': props.variant === 'outlined',
        'ws-alert--variant-elevated': props.variant === 'elevated',

        'ws-alert--closable': props.closable,
        'ws-alert--block-actions': props.blockActions,
      },
    ]"
    :inline-actions="!props.blockActions"
    rounded
    :aid="props.aid"
  >
    <template #avatar>
      <slot name="icon">
        <QIcon v-if="icon" size="xs" :name="icon" />
      </slot>
    </template>

    <div class="ws-alert__content">
      <div
        v-if="props.title"
        class="ws-alert__content__title text-sm font-semibold"
      >
        {{ props.title }}
      </div>

      <slot />
    </div>

    <WsIconButton
      v-if="closable"
      class="ws-alert__close-button"
      variant="text"
      size="md"
      color="gray-400"
      :aid="`${props.aid}.close_button`"
      @click="open.value = false"
    >
      <span class="fa-light fa-xmark" />
    </WsIconButton>

    <template v-if="$slots.actions" #action>
      <slot name="actions" />
    </template>
  </QBanner>
</template>

<style lang="scss" scoped>
.ws-alert {
  position: relative;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 2px 14px;
  min-height: 50px;
  background-color: var(--ws-alert-bg-color);

  :deep(.q-banner__avatar) {
    margin-top: 12px;
  }

  &--type-info {
    --ws-alert-border-color: rgb(var(--color-gray-200));
    --ws-alert-bg-color: #fff;
    --ws-alert-icon-color: rgb(var(--color-primary));
  }

  &--type-warning {
    --ws-alert-border-color: rgb(var(--color-warning));
    --ws-alert-bg-color: rgb(var(--color-warning-50));
    --ws-alert-icon-color: rgb(var(--color-warning));
  }

  &--type-danger {
    --ws-alert-border-color: rgb(var(--color-danger));
    --ws-alert-bg-color: rgb(var(--color-danger-50));
    --ws-alert-icon-color: rgb(var(--color-danger));
  }

  &--type-info-dark {
    --ws-alert-border-color: rgb(var(--color-gray-200));
    --ws-alert-bg-color: rgb(var(--color-primary-50));
    --ws-alert-icon-color: rgb(var(--color-primary));
  }

  &--variant-outlined,
  &--variant-elevated {
    border-color: var(--ws-alert-border-color);
  }

  &--variant-elevated {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1); // TODO: move this to a css var (wating for Moran to set all shadows options)
  }

  // if closable and no title - padding for the content element
  // if closable and has title - padding for the title element
  &--closable &__content:not(:has(&__content__title)),
  &--closable &__content > &__content__title {
    padding-inline-end: 30px;
  }

  &--block-actions:deep(.q-banner__actions) {
    margin-block-end: 12px;
  }

  &__content {
    // TODO: @include text-sm;
    font-size: 0.8125rem;
    line-height: calc(19 / 13);
    padding-block: 12px;

    &__title {
      margin-bottom: 16px;
    }
  }

  &__close-button {
    position: absolute;
    top: 3px;
    inset-inline-end: 6px;
  }

  .q-icon {
    color: var(--ws-alert-icon-color);
  }
}
</style>
