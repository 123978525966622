import { Notify } from 'quasar';

/**
 * Statuses as defined in figma\
 * https://www.figma.com/design/FOKSeotkMONlj0dFEyNf4S/Design-system?node-id=4951-1683&node-type=canvas&m=dev
 */
export enum ToastStatus {
  Completed = 'Completed',
  Error = 'Error',
  Loading = 'Loading',
}

export enum ToastTimeout {
  Short = 4000,
  Default = 8000,
  Long = 16000,
}

export type ToastOptions = {
  /** Automation ID */
  aid: string;

  /** Message to display */
  message: string;

  /**
   * The good/bad/ugly - see the enum\
   * TODO: implement loading status
   */
  status: ToastStatus;

  /**
   * How long to display the toast, use the
   */
  timeout?: ToastTimeout;
};

type StatusProps = {
  color: string;
  icon: string;
};

const statusMap: Record<ToastStatus, StatusProps> = {
  [ToastStatus.Completed]: {
    color: 'grey-500',
    icon: 'fa-regular fa-check',
  },
  [ToastStatus.Error]: {
    color: 'danger',
    icon: 'fa-regular fa-circle-exclamation',
  },
  [ToastStatus.Loading]: {
    color: 'grey-500',
    icon: '',
  },
};

/**
 * Display a floating message to the user\
 * WIP: this is phase one of the implementation where we only need to provide an error\
 *
 * [Figma](https://www.figma.com/design/FOKSeotkMONlj0dFEyNf4S/Design-system?node-id=5685-5513&node-type=text&m=dev) &
 * [Prd](https://docs.google.com/document/d/1cLT9jJOLbiXB3vw7R9aWEsLIR-RPRPLAFeULOkg5gzo/edit?tab=t.0#heading=h.shzqrferfmam)
 * @param options See ToastOptions type for more details
 */
export function toast(options: ToastOptions): void {
  const { status, aid } = options;
  const { color, icon } = statusMap[status];

  let timeout: number = options.timeout || ToastTimeout.Default;
  if (status === ToastStatus.Loading) timeout = 0;

  Notify.create({
    message: options.message,
    icon,
    color,
    timeout,
    position: 'bottom-left',
    group: false,
    actions: [
      {
        icon: 'close',
        size: 'sm',
        color: 'white',
        round: true,
      },
    ],
    attrs: {
      aid,
    },
  });

  // FYI: see style.scss for overrides
}
